/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Avis() {


  const settings = {
      className: "slider variable-width",
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
  };


  return (
      <div className="bg-[#3333e6] pt-8 lg:py-16">
          <div className="max-w-7xl mx-auto px-8 sm:px-6 lg:px-8">

              <div
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
              >
                <div className="flex-shrink-0">
                  <StaticImage
                    className="object-cover object-center"
                    src="../../images/google-logo.png"
                    alt="Avis Google 1"
                    height={100}
                  />
                </div>
                <div className="min-w-0 flex-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-base font-medium text-gray-900">blicko</p>
                    <p className="truncate text-sm text-gray-500">12 Rue Dubois, 69002 Lyon</p>
                    <p className="truncate text-sm text-gray-500">
                    <ul class="mb-0 flex">
                      <li className="text-base pr-2">
                        4,9
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li className="text-base pl-2">
                        236 avis Google
                      </li>
                    </ul>
                    </p>
                </div>
              </div>

            
              <Slider {...settings} className="mx-auto grid grid-cols-4 align-middle w-full mt-4  items-center justify-items-center px-4 text-white">
                
                {/* 
                Etoile vide
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                    <path fill="currentColor"
                      d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
                  </svg>
                </li> 
                
                Etoile moitier
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                    <path fill="currentColor"
                      d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                  </svg>
                </li>

                Etoile plein
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                    <path fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                  </svg>
                </li>

                */}

                <div className="p-4 flex justify-center items-center">
                  <div class="mb-12 md:mb-0">
                    <div class="mb-6 flex justify-center">
                      <StaticImage
                        className="rounded-full shadow-lg dark:shadow-black/20"
                        src="../../images/roche.png"
                        alt="Avis Google 1"
                      />
                    </div>
                    <h5 class="mb-2 text-lg font-bold">Roche Laurence</h5>
                    {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                      Graphic Designer
                    </h6> */}
                    <p class="mb-4 text-sm">
                    Très satisfaite de cette entreprise : réactivité, efficacité, professionnalisme !
  L'intervention a été très rapide. Ravie du travail effectué, résultat nickel et propre.
  L'application est très bien faite : devis, vidéos, diagnostics …
  Nous n'hésiterons pas à repasser par cette entreprise en cas de problème !
                      
                      
                    </p>
                    <ul class="mb-0 flex justify-center">
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="p-4 flex justify-center items-center">
                  <div class="mb-12 md:mb-0">
                    <div class="mb-6 flex justify-center">
                      <StaticImage
                        className="rounded-full shadow-lg dark:shadow-black/20"
                        src="../../images/masle.png"
                        alt="Avis Google 1"
                      />
                    </div>
                    <h5 class="mb-2 text-lg font-bold">Louise Maslé</h5>
                    {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                      Graphic Designer
                    </h6> */}
                    <p class="mb-4 text-sm">

                    Application parfaite ! C’est super d’être pris en charge rapidement et de pouvoir bénéficier d’un bon diagnostic. On se sent en confiance et c’est très plaisant ! Merci l’équipe Blicko pour la réparation de mon chauffe-eau !
                      
                      
                    </p>
                    <ul class="mb-0 flex justify-center">
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="p-4 flex justify-center items-center">
                  <div class="mb-12 md:mb-0">
                    <div class="mb-6 flex justify-center">
                      <StaticImage
                        className="rounded-full shadow-lg dark:shadow-black/20"
                        src="../../images/rand.png"
                        alt="Avis Google 1"
                      />
                    </div>
                    <h5 class="mb-2 text-lg font-bold">Jessica Rand</h5>
                    {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                      Graphic Designer
                    </h6> */}
                    <p class="mb-4 text-sm">
                    
                    L'équipe de Blicko est super ! Ils sont réactifs, très accueillants et ils font du très bon travail ! Mon problème a été solutionné tout de suite et en plus le plombier est réparti en me laissant un petit savon ^^ Je suis très contente de cette prestation, je recommande vivement !


                     
                    </p>
                    <ul class="mb-0 flex justify-center">
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="p-4 flex justify-center items-center">
                  <div class="mb-12 md:mb-0">
                    <div class="mb-6 flex justify-center">
                      <StaticImage
                        className="rounded-full shadow-lg dark:shadow-black/20"
                        src="../../images/rosselot.png"
                        alt="Avis Google 1"
                      />
                    </div>
                    <h5 class="mb-2 text-lg font-bold">Alice Rousselot</h5>
                    {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                      Graphic Designer
                    </h6> */}
                    <p class="mb-4 text-sm">

                    Je suis enchantée par l'intervention que je viens juste d'avoir. J'ai eu le plaisir de recevoir Hugo: charmant, efficace, très poli, travail super propre et minutieux ! Hugo a pris le temps de répondre à toutes mes interrogations qui étaient nombreuses ! Tout fonctionne à merveille maintenant ! Je recommande vivement Hugo, son charmant sourire et son travail impeccable ! Et merci beaucoup pour le savon de Marseille qui sent hyper bon ;)

                     
                    </p>
                    <ul class="mb-0 flex justify-center">
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="p-4 flex justify-center items-center">
                  <div class="mb-12 md:mb-0">
                    <div class="mb-6 flex justify-center">
                      <StaticImage
                        className="rounded-full shadow-lg dark:shadow-black/20"
                        src="../../images/peter.png"
                        alt="Avis Google 1"
                      />
                    </div>
                    <h5 class="mb-2 text-lg font-bold">Peter Mounier</h5>
                    <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                    Local Guide · Niveau 3
                    </h6>
                    <p class="mb-4 text-sm">
                    
                    
                    Blicko que j'ai découvert par l'intermédiaire de ma régie à l'occasion d'un problème de fuite d'eau sur mon chauffe eau. Plateforme en ligne très pratique pour soumettre le problème (si j'avais un reproche à faire ce serait peut être la réactivité de la plateforme en elle-même) avec en plus la possibilité de joindre des vidéos et photos. Pour les vidéos, des tutoriels ont même été fait pour savoir quoi montrer : très bien pensé. Au niveau de l'intervention, super prestation : ponctuel, professionnel, réactif et agréable. Je recommanderai Blicko les yeux fermés. Continuez comme ça !


                    </p>
                    <ul class="mb-0 flex justify-center">
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="p-4 flex justify-center items-center">
                  <div class="mb-12 md:mb-0">
                    <div class="mb-6 flex justify-center">
                      <StaticImage
                        className="rounded-full shadow-lg dark:shadow-black/20"
                        src="../../images/roche-sylvain.png"
                        alt="Avis Google 1"
                      />
                    </div>
                    <h5 class="mb-2 text-lg font-bold">Sylvain Roche</h5>
                    {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                    Local Guide · Niveau 3
                    </h6> */}
                    <p class="mb-4 text-sm">
                    
                    Intervenant tres sympa, compétent et pas avare de conseils. Travail efficace propre et soigné. Excellente prestation
                
                    </p>
                    <ul class="mb-0 flex justify-center">
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>

                
              </Slider>
          </div>
            
          
      </div>
  )
}
